import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  Link,
  List,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IconInformation = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconInformation;
    return <Component {...props} />;
  },
});

const Tooltip = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Tooltip;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/tooltip.example');
const overrideSnippet = require('raw-loader!../../../data/snippets/tooltip-overridePosition.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice 
        code
        componentName="Tooltip"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-tooltip--docs"
        additionalContent={
          <span>
            The tooltip component has been completely refactored to use the{' '}
            <Link
              href="https://www.radix-ui.com/primitives/docs/components/tooltip"
              type="article">
              Radix Tooltip
            </Link>{' '}
            under the hood.
          </span>
        }
      />
      <Section title="How to use">
        <Paragraph>
          The <code>Tooltip</code> extends the{' '}
          <Link href="https://reacttooltip.github.io/react-tooltip/">
            react-tooltip
          </Link>{' '}
          library. If you have issues that aren't addressed here, check their
          documentation. More documentation can be found{' '}
          <Link href="https://github.com/ReactTooltip/react-tooltip/">
            in the Github docs
          </Link>
          .
        </Paragraph>
        <Paragraph>
          To use <code>Tooltips</code>, first add a <code>Tooltip</code>{' '}
          component to the page you are wanting to use it on. You can include
          any of the <Link href="#Props">props</Link> listed below.
        </Paragraph>
        <Paragraph>
          Once the <code>Tooltip</code> is initialized, you can use certain{' '}
          <code>data-*</code> attributes on any element to make the{' '}
          <code>Tooltip</code> display relative to that element. Some common
          examples are:
        </Paragraph>
        <List type="unordered">
          <li>
            <code>data-tip</code> - Sets the text for the tooltip specific to
            that element.
          </li>
          <li>
            <code>data-for</code> - Binds the element to the{' '}
            <code>Tooltip</code> with an <code>id</code> that matches the given
            value.
          </li>
          <li>
            <code>data-place</code> - Sets the position of the tooltip specific
            to that element. Overrides <code>position</code> prop.
          </li>
          <li>
            <code>data-iscapture</code> - Boolean string value which, if set to{' '}
            <code>"true"</code>, will prevent the tooltip from displaying if the
            user scrolls while it is visible.
          </li>
          <li>
            <code>data-event</code> - Allows for custom event trigger. Default
            is <code>"hover"</code>. Accepts multiple values, ex:{' '}
            <code>"focus click"</code>.
          </li>
          <li>
            <code>data-event-off</code> - Same as <code>data-event</code>, but
            to hide the tooltip.
          </li>
          <li>
            See the rest in the{' '}
            <Link href="https://reacttooltip.github.io/react-tooltip/">
              react-tooltip v4 docs
            </Link>
            .
          </li>
        </List>
        <br />
        <CodeSnippet
          scope={{
            React: React,
            IconInformation: IconInformation,
            Tooltip: Tooltip,
          }}
          code={snippet}
          platform="react"
          gitHubLink="tooltip"
          disableCodeEditing
        />
      </Section>

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="tooltip" />

          <EnvironmentPartial />

          <PropListItem name="overridePosition" types={['string']}>
            <Text>
              Can be used to prevent the tooltip from going off the edge of the
              screen. Use the code snippet below to make this happen.
            </Text>
            <CodeSnippet
              scope={{
                React: React,
                Tooltip: Tooltip,
              }}
              code={overrideSnippet}
              platform="react"
              gitHubLink="tooltip"
              disableCodeEditing
            />
          </PropListItem>

          <PropListItem name="position" types={['string']}>
            <Text>Where the tooltip will appear in relation to element.</Text>
            <List type="unordered">
              <li>
                <code>top</code>
              </li>
              <li>
                <code>bottom</code>
              </li>
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="type" types={['string']}>
            <Text>Determines the type of tooltip.</Text>
            <List type="unordered">
              <li>
                <code>instruction</code> (default)
              </li>
              <li>
                <code>label</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Functional">
          <PropListItem name="delayTime" types={['number']}>
            <Text>The wait before a tooltip appears.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from the{' '}
              <Link href="https://reacttooltip.github.io/react-tooltip/">
                React Tooltip
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            You can have one or more tooltip components on your page, but it
            obviously is more performant to just have one. You can change
            individual settings for each element you want a tooltip on via{' '}
            <code>data</code> attributes; read more on the{' '}
            <Link href="https://reacttooltip.github.io/react-tooltip/">
              react-tooltip
            </Link>{' '}
            website.
          </li>
          <li>
            If you're displaying a tooltip on any type of component that might
            have portaling (like a modal, for example), be sure to include the{' '}
            <code>bodyMode</code> prop on the tooltip to correctly place the
            tooltip after the portaled component mounts.
          </li>
          <li>
            If you're finding a component isn't passing down the{' '}
            <code>data-tip</code> attribute properly, put it on a wrapped{' '}
            <code>span</code> instead to get the tooltip and not break your
            layout.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Tooltip"
      />
    </PlatformTab>
  );
};

export default WebTab;
